import React, { useState, useCallback, useContext, useEffect } from "react"
import {
  Text,
  Card,
  LegacyStack,
  ProgressBar,
  Collapsible,
  Icon,
  Box,
  BlockStack,
  InlineGrid,
  RadioButton,
  Button,
  Bleed,
  ButtonGroup,
} from "@shopify/polaris"
import {
  ChevronUpIcon,
  ChevronDownIcon,
  ExternalIcon,
  CheckCircleIcon,
  LayoutPopupIcon,
  LayoutSectionIcon,
  LayoutSidebarRightIcon,
  XIcon,
} from "@shopify/polaris-icons"
import { navigate } from "gatsby"
import { doc } from "firebase/firestore"
import { useDocumentData } from "react-firebase-hooks/firestore"
import FirebaseContext from "../providers/firebase"
import getCheckoutProfiles from "../helpers/getCheckoutProfiles"
import CustomizationSelectionCard from "./customizationSelectionCard"
import OnBoardingFeatureCard from "./onBoardingFeatureCard"
import CardDivider from "./cardDivider"
import styled from "styled-components"
import getMainThemeId from "../helpers/getMainThemeId"
const ButtonWrapper = styled.button`
  width: 100%;
  cursor: pointer;
  border: none;
  background: none;
  text-align: left;
`
const allCheckout = [
  "shopify_plus",
  "affiliate",
  "partner_test",
  "plus_partner_sandbox",
  "staff",
  "staff_business",
  "custom",
  "unlimited",
]

const OnboardingForNew = (props) => {
  const { rules, initStep, id, extensionName, cartUpsellName } = props
  const { firebase, shop, token, host } = useContext(FirebaseContext)
  const [loading, setLoading] = useState(false)
  const shopDoc = doc(firebase.firestore, "shops", shop)
  const [shopData, shopDataLoading, shopDataError] = useDocumentData(shopDoc, {
    transform: (snap) => snap.shopData,
  })
  const [mainThemeId, setMainThemeId] = useState(null)

  const getThemeId = async () => {
    try {
      const fetchedThemeId = await getMainThemeId(token, shop, host)
      if (fetchedThemeId?.data?.mainThemeId) {
        setMainThemeId(fetchedThemeId.data.mainThemeId)
      }
    } catch (err) {
      console.log("Error in set main theme id: ", err)
    }
  }

  useEffect(() => {
    getThemeId().catch((err) =>
      console.log("Error in fetch main theme id: ", err)
    )
  }, [])
  const createOfferHandler = async () => {
        navigate("/app/offers/createCheckout", {
          state: { rules: rules, from: "onboarding" },
          replace: true,
        })
  }

  const createCartOfferHandler = useCallback(() => {
    navigate("/app/offers/createCartType", {
      state: { rules: rules, from: "onboarding" },
      replace: true,
    })
  }, [rules])
  const createCartPageHandler = useCallback(() => {
    navigate("/app/offers/createCart", {
      state: { rules: rules, from: "onboarding" },
      replace: true,
    })
  }, [rules])
  const createCartDrawerHandler = useCallback(() => {
    navigate("/app/offers/createCartDrawer", {
      state: { rules: rules, from: "onboarding" },
      replace: true,
    })
  }, [rules])
  const createCartPopupHandler = useCallback(() => {
    navigate("/app/offers/cart/v1/createCart", {
      state: { rules: rules, from: "onboarding" },
      replace: true,
    })
  }, [rules])
  const createPostPurchaseOfferHandler = useCallback(() => {
    navigate("/app/offers/createPostPurchase", {
      state: { rules: rules, from: "onboarding" },
      replace: true,
    })
  }, [rules])
  const createProductOfferHandler = useCallback(() => {
    navigate("/app/offers/createProduct", {
      state: { rules: rules, from: "onboarding" },
      replace: true,
    })
  }, [rules])
  const createCustomContentHandler = useCallback(() => {
    navigate("/app/customizations/customcontent/createCustomContent", {
      replace: true,
    })
  }, [])

  const createCustomFieldHandler = useCallback(() => {
    navigate("/app/customizations/customfield/createCustomField", {
      replace: true,
    })
  }, [])

  const createRewardsBarHandler = useCallback(() => {
    navigate(`/app/customizations/onboardingLastStep`, {
      state: {
        backLink: "/app/customizations",
        extensionName: "Rewards Bar",
      },
      replace: true,
    })
  }, [])

  const learnMoreHandler = useCallback(() => {
    window.open(
      `https://help.upsellplus.com/en/article/how-to-add-a-free-shipping-bar-to-your-checkout-ibr8aj/`,
      "_blank"
    )
  }, [])

  const createAddressBlockerHandler = useCallback(() => {
    navigate("/app/customizations/addressblocker/createAddressBlocker", {
      replace: true,
    })
  }, [])

  const createHidePaymentMethodsHandler = useCallback(() => {
    navigate("/app/customizations/hidepaymentmethods/createHidePaymentMethods", {
      replace: true,
    })
  }, [])

  const createHideDeliveryMethodsHandler = useCallback(() => {
    navigate("/app/customizations/hidedeliverymethods/createHideDeliveryMethods", {
      replace: true,
    })
  }, [])

  const createCheckoutBrandingHandler = useCallback(() => {
    navigate("/app/settings/branding", {
      state: {
        backLink: "customizations",
      },
      replace: true,
    })
  }, [])
  const REVENUE_SELECTION = [
    {
      title: "Checkout upsell",
      content: "Increase AOV with upsells and cross-sells",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/checkout-upsell-onboarding-v1.png?alt=media&token=e312bdc2-e9fe-4a99-8fe2-c7a1d986f090",
      url: createOfferHandler,
      grey:
        shopData &&
        shopData.plan_name &&
        allCheckout.indexOf(shopData.plan_name) === -1,
      loading: loading,
      plusOnly: true,
    },
    {
      title: "Product page upsell",
      content: "Recommend products on the product page",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/product-upsell-onboarding-v1.png?alt=media&token=e2335399-aad7-4c9d-85a7-bb557037ccb8",
      url: createProductOfferHandler,
    },
    {
      title: "Cart upsell",
      content: "Cart drawer, cart page or a popup",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/cart-onboarding-v1.png?alt=media&token=6d43d745-f07d-4a58-ab5c-792a32dc5c40",
      url: createCartOfferHandler,
    },
    {
      title: "Post-purchase upsell",
      content: "Boost AOV with upsells after the purchase",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/postpurchase-onboarding-v1.png?alt=media&token=93636d0c-acae-4ce2-a508-9e1044b4ed9a",
      url: createPostPurchaseOfferHandler,
    },
  ]
  const CART_OFFER_SELECTION = [
    {
      title: "Popup Upsell",
      type: "Cart upsell",
      content: "This will pop up on the bottom right of the page",
      icon: LayoutPopupIcon,
      url: createCartPopupHandler,
    },
    {
      title: "Cart Page Upsell",
      type: "Cart upsell",
      content: "Show a ‘You may also like’ section on your main cart page",
      icon: LayoutSectionIcon,
      url: createCartPageHandler,
    },
    {
      title: "Cart Drawer Upsell",
      type: "Cart upsell",
      content: "This will show in your side cart drawer or slide cart",
      icon: LayoutSidebarRightIcon,
      url: createCartDrawerHandler,
    },
  ]
  const CONTENT_SELECTION = [
    {
      title: "Banner",
      content: "Show info or a warning",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/banner-selection.jpg?alt=media&token=3f7a3ddd-7528-48fd-a1d6-dab7e31fe7bd",
      url: createCustomContentHandler,
      grey:
        shopData &&
        shopData.plan_name &&
        allCheckout.indexOf(shopData.plan_name) === -1,
    },
    {
      title: "Image",
      content: "Show an image",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/image-selection.jpg?alt=media&token=36b5dfd2-d172-4333-97fc-a222f2889827",
      url: createCustomContentHandler,
      grey:
        shopData &&
        shopData.plan_name &&
        allCheckout.indexOf(shopData.plan_name) === -1,
    },
    {
      title: "Rewards bar",
      content: "Add a free shipping or free gift progress bar",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/rewards-bar-selection.jpg?alt=media&token=c1a2a683-2c07-4607-9540-052d91a45315",
      url: createRewardsBarHandler,
      grey:
        shopData &&
        shopData.plan_name &&
        allCheckout.indexOf(shopData.plan_name) === -1,
      learnMore: learnMoreHandler,
    },
    {
      title: "Payment icons",
      content: "Show payment icons to instill trust",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/payment-icons-selection.jpg?alt=media&token=d9c0fd5e-c749-49df-9588-8839f80660d1",
      url: createCustomContentHandler,
      grey:
        shopData &&
        shopData.plan_name &&
        allCheckout.indexOf(shopData.plan_name) === -1,
    },
    {
      title: "Trust badge",
      content: "Add trust badges",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/trust-badge-selection.jpg?alt=media&token=7be735e3-b60b-4fc0-bd17-520b7bbce66f",
      url: createCustomContentHandler,
      grey:
        shopData &&
        shopData.plan_name &&
        allCheckout.indexOf(shopData.plan_name) === -1,
    },
    {
      title: "Gift note",
      content: "Give customers the option to leave a gift note",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/gift-note-selection.jpg?alt=media&token=3e667ef0-cd28-4169-a7f2-1c7d30c3385e",
      url: createCustomFieldHandler,
      grey:
        shopData &&
        shopData.plan_name &&
        allCheckout.indexOf(shopData.plan_name) === -1,
    },
    {
      title: "Hide payment methods",
      content: "Hide payment methods",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/hide-payment-methods.jpg?alt=media&token=ec44e095-5762-4e37-b154-f6c263f36ba0",
      url: createHidePaymentMethodsHandler,
    },
    {
      title: "Hide delivery methods",
      content: "Hide delivery methods",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/hide-delivery-methods-selection.jpg?alt=media&token=d23fa9a5-307f-4ae5-a82d-3dde689d421e",
      url: createHideDeliveryMethodsHandler,
    },
    {
      title: "Address validator",
      content: "Validate address fields and block checkout if invalid",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/address-validator-selection.jpg?alt=media&token=40bb7168-5290-4d62-bf18-3ded0441dfef",
      url: createAddressBlockerHandler,
    },
    {
      title: "Branding and styling",
      content: "Control the colors, fonts and styling of your checkout",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/branding-and-styleing-selection.jpg?alt=media&token=83b95843-9b0a-4b2f-885c-0ad5b9b3dc62",
      url: createCheckoutBrandingHandler,
    },
  ]
  const [step, setStep] = useState(initStep > 1 ? 3 : initStep)
  /*
  const [feature, setFeature] = useState("offers")
  const [selectedCard, setSelectedCard] = useState(
    feature === "offers"
      ? REVENUE_SELECTION[0].title
      : CONTENT_SELECTION[0].title
  )
  */
  const [selectedCard, setSelectedCard] = useState(
    extensionName ? extensionName : REVENUE_SELECTION[0].title
  )
  const [selectedCartUpsell, setSelectedCartUpsell] = useState(
    cartUpsellName ? cartUpsellName : CART_OFFER_SELECTION[0].title
  )
  const domain = shopData && shopData.domain
  /*
  const handleFeatureChange = useCallback((newValue) => {
    console.log(newValue)
    setFeature(newValue)
  }, [])
  */
  const handleCardChange = useCallback((newValue) => {
    setSelectedCard(newValue)
    if ("Cart upsell" === newValue) {
      setStep(2)
    }
  }, [])
  const handleCartUpsellChange = useCallback((newValue) => {
    setSelectedCartUpsell(newValue)
  }, [])
  const handleToggle = (value) => {
    if (value === step) {
      setStep(0)
    } else {
      setStep(value)
    }
  }

  const STEP2 = [
    {
      extensionName: "Checkout upsell",
      title: "Enable the Checkout upsell extension in your checkout",
      subtitle: "Select where you want to show upsell offer",
      steps: [
        {
          title: "Add App block",
          desp: "Go to Checkout Editor and click  on ‘Add app block’",
          image:
            "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-ce-step1.png?alt=media&token=3a7d5618-f2b8-4cf7-9322-53ee96bc020c",
        },
        {
          title: "Select 'Checkout upsell' by UpsellPlus",
          desp: "From the list find and select ‘Checkout upsell’",
          image:
            "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-ce-step2.png?alt=media&token=985be51e-5c34-4dca-8540-6a190815778d",
        },
        {
          title: "Drag & place in section",
          desp: "Select and drag ‘Upsellplus Checkout’ in any section",
          image:
            "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-ce-step3.png?alt=media&token=354eb8d6-6bd9-447e-8c72-6c8aa23b5705",
        },
        {
          title: "Save",
          desp: "After making the changes be sure to click on ‘Save’",
          image:
            "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-ce-step4.png?alt=media&token=ffeb05ee-9af8-4eaf-9bdc-1cd5202d092c",
        },
      ],
    },
    {
      extensionName: "Product page upsell",
      title: "Add Theme app extension to theme product page",
      subtitle: "Add the theme app extension to your theme product page",
      steps: [
        {
          title: "Add App block",
          desp: "Click on ‘Add app block’",
          image:
            "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-product-step1.png?alt=media&token=952d642f-5c61-4d21-afa8-08e2b37732c3",
        },
        {
          title: "Select Product Upsell",
          desp: "Click the ‘UpsellPlus PDP Upsell’ block",
          image:
            "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-product-step2.png?alt=media&token=63ec41b3-a506-4b89-9e08-475c9909494a",
        },
        {
          title: "Save",
          desp: "Click on ‘Save’",
          image:
            "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-product-step3.png?alt=media&token=5537d485-66d8-4366-a24c-d34b980e5d2c",
        },
      ],
    },
    {
      extensionName: "Post-purchase upsell",
      title: "Enable UpsellPlus in Post-Purchase settings",
      subtitle: "Enable UpsellPlus in Post-Purchase settings",
      steps: [
        {
          title: "Go to Post-purchase page",
          desp: "Go to Post-Purchase settings and scroll to Post-purchase page",
          image:
            "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-pp-step1.png?alt=media&token=56749299-efac-4056-b92a-3f38a896bd0b",
        },
        {
          title: "Select ‘UpsellPlus’",
          desp: "Select ‘UpsellPlus’ under Post-Purchase settings",
          image:
            "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-pp-step2.png?alt=media&token=5764b34d-8730-443c-88ec-1af3d226131d",
        },
        {
          title: "Save",
          desp: "After making the changes be sure to click on ‘Save’",
          image:
            "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-pp-step3.png?alt=media&token=52790ea9-e3fe-43c1-ac93-dad0671af142",
        },
      ],
    },
    {
      extensionName: "Popup Upsell",
      title: "Preview Offer",
      subtitle: "Preview offer to make sure everything is working as expected",
      steps: [
        {
          title: "Go to Store",
          desp: "Click on the button below to go to store",
          image:
            "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-popup-step1.png?alt=media&token=018bdc30-3da4-4b47-8e64-5716550cbd2b",
        },
        {
          title: "Confirm if you see the Cart Popup",
          desp: "Do you see the Cart popup?",
          image:
            "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-popup-step2.png?alt=media&token=312269cb-e79b-4568-817d-e13607c50d40",
        },
      ],
    },
    {
      extensionName: "Cart Page Upsell",
      title: "Configure Upsell Offer",
      subtitle: "Add offer to the app section of your cart page",
      steps: [
        {
          title: "Add section",
          desp: "Click on ‘Add section’ under ‘Template’",
          image:
            "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-cart-page-step1.png?alt=media&token=29c48050-d7d7-42fc-a06f-1e431be6e964",
        },
        {
          title: "Select ‘UpsellPlus Cart Upsell’",
          desp: "Click on ‘UpsellPlus Cart Upsell’",
          image:
            "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-cart-page-step2.png?alt=media&token=53af2a46-a844-4953-a05f-95c6ce4b9331",
        },
        {
          title: "Save",
          desp: "After you see the section, click on ‘Save’",
          image:
            "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-cart-page-step3.png?alt=media&token=80682839-16c6-4528-8aa1-ba07a9297adf",
        },
      ],
    },
    {
      extensionName: "Cart Drawer Upsell",
      title: "Configure Upsell Offer",
      subtitle: "Connect your theme to UpsellPlus",
      steps: [
        {
          title: "View Setup Guide",
          desp: "Follow a step by step guide to set it up yourself",
          image:
            "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-cart-drawer-step2.png?alt=media&token=47e35006-da15-4e2c-8bb3-f60847c3e20d",
        },
        {
          title: "Or Request Setup",
          desp: "Click on ‘Request setup’ to talk to our team on live chat",
          image:
            "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-cart-drawer-step1.png?alt=media&token=30dd347e-a2c2-46be-9ec7-d30cdca72856",
        },
      ],
    },
  ]

  const dropdownButtonMarkup = (title, open, initStep, currentStep) => {
    return (
      <Box paddingInline="200">
        <LegacyStack alignment="center">
          <LegacyStack.Item fill>
            <LegacyStack spacing="extraTight" alignment="center">
              {initStep > currentStep ? (
                <Icon source={CheckCircleIcon} tone="primary"></Icon>
              ) : (
                <RadioButton checked={false} />
              )}
              <Text as="p" fontWeight={open ? "semibold" : "regular"}>
                {title}
              </Text>
            </LegacyStack>
          </LegacyStack.Item>
          <LegacyStack.Item>
            {open ? (
              <Icon source={ChevronUpIcon}></Icon>
            ) : (
              <Icon source={ChevronDownIcon}></Icon>
            )}
          </LegacyStack.Item>
        </LegacyStack>
      </Box>
    )
  }
  return (
    <Box paddingBlockEnd="800">
    <Card>
      <Box padding="200">
        <BlockStack gap="100">
          <InlineGrid columns="1fr auto">
            <Text as="h2" variant="headingMd">
              Setup guide
            </Text>
            <div style={{cursor: 'pointer'}} 
                onClick={() => {
                  localStorage.setItem('close_onboarding',true);
                  navigate("/app");
                  }}>
              <Icon
                source={XIcon}
                tone="base"
              />
            </div>
          </InlineGrid>
          
          <Text as="p" variant="bodyMd">
            Use this personalized guide to get you started with UpsellPlus
          </Text>
          <LegacyStack distribution="fillEvenly">
            <LegacyStack.Item fill>
              <Text as="span" tone="subdued">
                {initStep === 1
                  ? "0"
                  : "Cart upsell" === selectedCard
                  ? "2"
                  : "1"}{" "}
                of {"Cart upsell" === selectedCard ? "3" : "2"} steps completed
              </Text>
            </LegacyStack.Item>
            <LegacyStack.Item>
              <div style={{ width: "700px" }}>
                <ProgressBar
                  progress={
                    initStep === 1
                      ? 1
                      : "Cart upsell" === selectedCard
                      ? 66
                      : 50
                  }
                  tone="primary"
                  animated={false}
                />
              </div>
            </LegacyStack.Item>
          </LegacyStack>
        </BlockStack>
        {/*
        <Box paddingBlockStart="800">
          <BlockStack gap="100">
            <Text as="h2" variant="headingMd">
              Select Feature onboarding
            </Text>
            <LegacyStack alignment="center">
              <Card>
                <Bleed marginInline="400" marginBlock="400">
                  <Box
                    background={
                      feature === "offers" ? "bg-surface-brand-active" : ""
                    }
                  >
                    <Box padding="400">
                      <BlockStack gap="100">
                        <Card>
                          <Box width="250px">
                            <div style={{ textAlign: "center" }}>
                              <img
                                src="https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-create-offer.png?alt=media&token=9cda1991-1f65-4e45-a1b6-25dd50fb3691"
                                width="80"
                                height="84"
                              />
                            </div>
                          </Box>
                        </Card>
                        <div style={{ textAlign: "center" }}>
                          <div style={{ display: "inline-block" }}>
                            <LegacyStack
                              spacing="extraTight"
                              alignment="center"
                            >
                              <RadioButton
                                checked={feature === "offers"}
                                id="offers"
                                name="features"
                                onChange={() => handleFeatureChange("offers")}
                              />
                              <Text as="p" fontWeight="semibold">
                                Create Offers
                              </Text>
                            </LegacyStack>
                          </div>
                        </div>
                      </BlockStack>
                    </Box>
                  </Box>
                </Bleed>
              </Card>
              <Card>
                <Bleed marginInline="400" marginBlock="400">
                  <Box
                    background={
                      feature === "customizations"
                        ? "bg-surface-brand-active"
                        : ""
                    }
                  >
                    <Box padding="400">
                      <BlockStack gap="100">
                        <Card>
                          <Box width="250px">
                            <div style={{ textAlign: "center" }}>
                              <img
                                src="https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-create-customization-.png?alt=media&token=e21fea95-fa88-4036-a422-59d205fbbcc2"
                                width="80"
                                height="84"
                              />
                            </div>
                          </Box>
                        </Card>
                        <div style={{ textAlign: "center" }}>
                          <div style={{ display: "inline-block" }}>
                            <LegacyStack
                              spacing="extraTight"
                              alignment="center"
                            >
                              <RadioButton
                                checked={feature === "customizations"}
                                id="customizations"
                                name="features"
                                onChange={() =>
                                  handleFeatureChange("customizations")
                                }
                              />
                              <Text as="p" fontWeight="semibold">
                                Create Customizations
                              </Text>
                            </LegacyStack>
                          </div>
                        </div>
                      </BlockStack>
                    </Box>
                  </Box>
                </Bleed>
              </Card>
            </LegacyStack>
          </BlockStack>
        </Box>
        */}
      </Box>
      <CardDivider />
      <Bleed marginInline="400">
        <Box background={step === 1 ? "bg-surface-brand-active" : ""}>
          <Box paddingInline="200" paddingBlockStart="200">
            <ButtonWrapper
              onClick={(e) => {
                e.preventDefault()
                handleToggle(1)
              }}
              ariaExpanded={step === 1}
              ariaControls={`primary-button-collapsible`}
            >
              {dropdownButtonMarkup(
                "Create Upsell Offer",
                step === 1,
                initStep,
                1
              )}
            </ButtonWrapper>
            {step === 1 && (
              <Collapsible
                open={step === 1}
                id={`primary-button-collapsible`}
                transition={{
                  duration: "500ms",
                  timingFunction: "ease-in-out",
                }}
                expandOnPrint
              >
                <Box paddingInline="800" paddingBlockEnd="400">
                  <BlockStack gap="300">
                    <Text as="p" variant="bodyMd">
                      Select where you want to show upsell offer
                    </Text>
                    <InlineGrid
                      gap="100"
                      columns={{ xs: 1, sm: 2, md: 4, lg: 4, xl: 4 }}
                    >
                      {REVENUE_SELECTION.map((item, index) => {
                        return (
                          <OnBoardingFeatureCard
                            key={index}
                            {...item}
                            selectedCard={selectedCard}
                            setSelectedCard={setSelectedCard}
                            handleCardChange={handleCardChange}
                          />
                        )
                      })}
                    </InlineGrid>
                    <div style={{ textAlign: "left" }}>
                      <LegacyStack spacing="baseTight" alignment="center">
                        {"Cart upsell" !== selectedCard && (
                          <Button
                            variant="primary"
                            loading={loading}
                            onClick={() => {
                              REVENUE_SELECTION.find(
                                (i) => i.title === selectedCard
                              ).url()
                            }}
                          >
                            Proceed
                          </Button>
                        )}
                        <Button
                          variant="plain"
                          onClick={() => {
                            setStep(3)
                          }}
                        >
                          Skip step
                        </Button>
                      </LegacyStack>
                    </div>
                  </BlockStack>
                </Box>
              </Collapsible>
            )}
          </Box>
        </Box>
        {step !== 1 && <CardDivider />}
      </Bleed>
      {"Cart upsell" === selectedCard && (
        <Bleed marginInline="400">
          <Box background={step === 2 ? "bg-surface-brand-active" : ""}>
            <Box paddingInline="200" paddingBlockStart="200">
              <ButtonWrapper
                onClick={(e) => {
                  e.preventDefault()
                  handleToggle(2)
                }}
                ariaExpanded={step === 2}
                ariaControls={`primary-button-collapsible`}
              >
                {dropdownButtonMarkup(
                  "Select type of Cart Upsell",
                  step === 2,
                  initStep,
                  2
                )}
              </ButtonWrapper>
              {step === 2 && (
                <Collapsible
                  open={step === 2}
                  id={`primary-button-collapsible`}
                  transition={{
                    duration: "500ms",
                    timingFunction: "ease-in-out",
                  }}
                  expandOnPrint
                >
                  <Box paddingInline="800" paddingBlockEnd="400">
                    <BlockStack gap="300">
                      <Text as="p" variant="bodyMd">
                        Select the type of cart upsell that you want
                      </Text>
                      <InlineGrid
                        gap="100"
                        columns={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}
                      >
                        {CART_OFFER_SELECTION.map((item, index) => {
                          return (
                            <OnBoardingFeatureCard
                              key={index}
                              {...item}
                              selectedCard={selectedCartUpsell}
                              setSelectedCard={setSelectedCartUpsell}
                              handleCardChange={handleCartUpsellChange}
                            />
                          )
                        })}
                      </InlineGrid>
                      <div style={{ textAlign: "left" }}>
                        <LegacyStack spacing="baseTight" alignment="center">
                          <Button
                            variant="primary"
                            onClick={() => {
                              CART_OFFER_SELECTION.find(
                                (i) => i.title === selectedCartUpsell
                              ).url()
                            }}
                          >
                            Proceed
                          </Button>
                          <Button
                            variant="plain"
                            onClick={() => {
                              setStep(3)
                            }}
                          >
                            Skip step
                          </Button>
                        </LegacyStack>
                      </div>
                    </BlockStack>
                  </Box>
                </Collapsible>
              )}
            </Box>
          </Box>
          {step !== 2 && <CardDivider />}
        </Bleed>
      )}
      <Bleed marginInline="400" marginBlockEnd={step === 3 ? "400" : "0"}>
        <Box background={step === 3 ? "bg-surface-brand-active" : ""}>
          <Box paddingInline="200" paddingBlockStart="200">
            <ButtonWrapper
              onClick={(e) => {
                e.preventDefault()
                handleToggle(3)
              }}
              ariaExpanded={step === 3}
              ariaControls={`primary-button-collapsible`}
            >
              {dropdownButtonMarkup(
                STEP2.find(
                  (s) =>
                    s.extensionName ===
                    ("Cart upsell" === selectedCard
                      ? selectedCartUpsell
                      : selectedCard)
                ).title,
                step === 3,
                initStep,
                3
              )}
            </ButtonWrapper>
            {step === 3 && (
              <Collapsible
                open={step === 3}
                id={`primary-button-collapsible`}
                transition={{
                  duration: "500ms",
                  timingFunction: "ease-in-out",
                }}
                expandOnPrint
              >
                <Box paddingInline="800" paddingBlockEnd="400">
                  <BlockStack gap="300">
                    {STEP2.find(
                      (s) =>
                        s.extensionName ===
                        ("Cart upsell" === selectedCard
                          ? selectedCartUpsell
                          : selectedCard)
                    ).subtitle && (
                      <Text as="p" variant="bodyMd">
                        {
                          STEP2.find(
                            (s) =>
                              s.extensionName ===
                              ("Cart upsell" === selectedCard
                                ? selectedCartUpsell
                                : selectedCard)
                          ).subtitle
                        }
                      </Text>
                    )}

                    <InlineGrid
                      gap="100"
                      columns={"Checkout upsell" === selectedCard ? { xs: 1, sm: 2, md: 4, lg: 4, xl: 4 }:{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}
                    >
                      {STEP2.find(
                        (s) =>
                          s.extensionName ===
                          ("Cart upsell" === selectedCard
                            ? selectedCartUpsell
                            : selectedCard)
                      ).steps.map((step, index) => {
                        return (
                          <Card>
                            <Box padding="200">
                              <BlockStack gap="100">
                                <img src={step.image} alt={step.title} />
                                <Text as="p" fontWeight="semibold">
                                  {"Cart upsell" === selectedCard &&
                                  "Cart Drawer Upsell" === selectedCartUpsell
                                    ? `${step.title}`
                                    : `step ${index + 1}: ${step.title}`}
                                </Text>
                                <Text as="p">{step.desp}</Text>
                                {"Cart upsell" === selectedCard &&
                                  "Popup Upsell" === selectedCartUpsell &&
                                  index === 0 && (
                                    <Button
                                      icon={ExternalIcon}
                                      onClick={() => {
                                        window.open(
                                          `https://${domain}?upsellplus-preview=true`,
                                          "_blank"
                                        )
                                      }}
                                    >
                                      Go to store
                                    </Button>
                                  )}
                                {"Cart upsell" === selectedCard &&
                                  "Popup Upsell" === selectedCartUpsell &&
                                  index === 1 && (
                                    <ButtonGroup>
                                      <Button
                                        onClick={() => {
                                          navigate("/app/offers")
                                        }}
                                      >
                                        Yes, it is working perfectly
                                      </Button>
                                      <Button
                                        onClick={() => {
                                          if (window && window.$crisp) {
                                            window.$crisp.push([
                                              "do",
                                              "chat:open",
                                            ])
                                          }
                                        }}
                                      >
                                        No, I need help
                                      </Button>
                                    </ButtonGroup>
                                  )}
                                {"Cart upsell" === selectedCard &&
                                  "Cart Drawer Upsell" === selectedCartUpsell &&
                                  index === 0 && (
                                    <Button
                                      source={ExternalIcon}
                                      onClick={() => {
                                        window.open(
                                          `https://help.upsellplus.com/en/article/how-to-get-started-cart-upsells-cart-drawer-apfde9/`,
                                          "_blank"
                                        )
                                      }}
                                    >
                                      View Setup Guide
                                    </Button>
                                  )}
                                {"Cart upsell" === selectedCard &&
                                  "Cart Drawer Upsell" === selectedCartUpsell &&
                                  index === 1 && (
                                    <Button
                                      onClick={() => {
                                        if (window && window.$crisp) {
                                          window.$crisp.push([
                                            "do",
                                            "chat:open",
                                          ])
                                        }
                                      }}
                                    >
                                      Request Setup
                                    </Button>
                                  )}
                              </BlockStack>
                            </Box>
                          </Card>
                        )
                      })}
                    </InlineGrid>
                    <div style={{ textAlign: "left" }}>
                      <LegacyStack spacing="baseTight" alignment="center">
                        {"Checkout upsell" === selectedCard && (
                          <Button
                            icon={ExternalIcon}
                            variant="primary"
                            onClick={() => {
                              window.open(
                                `https://${domain}/admin/settings/checkout/editor`,
                                "_blank"
                              )
                            }}
                          >
                            Go to checkout editor
                          </Button>
                        )}
                        {("Product page upsell" === selectedCard ||
                          ("Cart upsell" === selectedCard &&
                            "Cart Page Upsell" === selectedCartUpsell)) && (
                          <Button
                            icon={ExternalIcon}
                            variant="primary"
                            onClick={() => {
                              window.open(
                                "Product page upsell" === selectedCard ? `https://${shop}/admin/themes/${mainThemeId}/editor?template=product&addAppBlockId=553fb951-397c-4171-8b32-7056a23ffd82/upsell-pdp-block&target=mainSection`:`https://${shop}/admin/themes/${mainThemeId}/editor?template=cart&addAppBlockId=553fb951-397c-4171-8b32-7056a23ffd82/upsell-app-block`,
                                "_blank"
                              )
                            }}
                          >
                            Go to theme editor
                          </Button>
                        )}
                        {"Post-purchase upsell" === selectedCard && (
                          <Button
                            icon={ExternalIcon}
                            variant="primary"
                            onClick={() => {
                              window.open(
                                `https://${domain}/admin/settings/checkout#additionalscripts`,
                                "_blank"
                              )
                            }}
                          >
                            Go to Post-Purchase Settings
                          </Button>
                        )}
                        <Button
                          onClick={() => {
                            navigate("/app/offers")
                          }}
                        >
                          Go to Offers Overview
                        </Button>
                        <Button
                          variant="plain"
                          onClick={() => {
                            localStorage.setItem('close_onboarding',true);
                            initStep === 1
                              ? navigate("/app/offers")
                              : navigate("/app")
                          }}
                        >
                          Skip step
                        </Button>
                      </LegacyStack>
                    </div>
                  </BlockStack>
                </Box>
              </Collapsible>
            )}
          </Box>
        </Box>
      </Bleed>
    </Card>
    </Box>
  )
}

export default OnboardingForNew
